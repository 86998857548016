define(['app', '$window', 'accessibleModalHelper', 'accessibilityAnnouncer'], (app, $window, accessibleModalHelper, accessibilityAnnouncer) => {

  const responsiveFlyoutMenu = () => {
    const component = {};
    const _config = {
      attribs: {
        modClass: 'data-modifier-class',
        dropdownOpenOnClick: false,
        dropdownOpened: null,
        dropdownToOpen: null,
        dropdownActive: 'data-js-dropdown-active',
        hasSubnav: 'data-js-element',
        menuExpand: 'data-expand-class',
        linkLevel: 'data-js-nav-level',
        levelOneClassJsSuffix: '-slide',
        jsNavigationIndex: 'data-js-nav-index',
      },
      selectors: {
        menu: '.responsiveFlyoutMenu_menu',
        menuStickyRow: '[data-js-element=responsiveFlyoutMenu_menuStickyRow]',
        menuStickyRowControl: '[data-js-menu-sticky-row-control]',
        menuButton: '[data-js-element=menuButton]',
        closeMenu: '[data-js-element=responsiveFlyoutMenu_closeMenu]',
        menuHome: '[data-js-element=responsiveFlyoutMenu_homeButton]',
        menuStores: '[data-js-element=responsiveFlyoutMenu_storesButton]',
        backToPrev: '[data-js-element=responsiveFlyoutMenu_backToPrev]',
        flyoutSlider: '[data-flyout]',
        subNav: '[data-js-element=subnav]',
        subNavLink: '[data-js-element=subnavLink], [data-js-element=subnavLink-complex]',
        subnavLinkSelector: '* > [data-js-element=subnavLink]',
        hasSubnav: '[data-js-element=hasSubnav]',
        menuUnderlay: '[data-js-element=menuUnderlay]',
        mobileUnderlay: '[data-js-element=smallMenuUnderlay]',
        levelOneContainer: '.responsiveFlyoutMenu_levelOne',
        levelTwoContainer: '.responsiveFlyoutMenu_levelTwo',
        cardsLevelContainer: '.responsiveFlyoutMenu_levelTwoInner-cards',
        levelOneLink: '[data-js-nav-level="1"]',
        levelTwoLink: '[data-js-nav-level="2"]',
        listsToHideWhenClosed: '.responsiveFlyoutMenu_levelTwoList, .brandsAToZ_brandGroups, .responsiveFlyoutMenu_featuredList',
        subMenu: '.responsiveSubMenu',
        levelTwoInnerContainer: '.responsiveFlyoutMenu_levelTwoInner',
        fastTrackSubmenuLink: '[data-fastTrack-submenu-link]',
        responsiveSubMenuList: 'responsiveSubMenu_list',
        jsNavigationIndex: '[data-js-nav-index]',
        responsiveFlyoutMenuLevelOneLink: '.responsiveFlyoutMenu_levelOneLink',
        brandsAToZDropdown: '.brandsAToZ_dropdown',
        brandsAToZLevelTwoSelector: '.brandsAToZ_brandGroup',
        brandsAToZLevelThreeSelector: '.brandsAToZ_brandItem > a',
        flyoutMenuLevelTwoSelector: '.responsiveFlyoutMenu_levelTwoLink:not(.responsiveFlyoutMenu_levelTwoLandingPageLink), .responsiveFlyoutMenu_menuCard',
        flyoutMenuLevelThreeSelector: '.responsiveFlyoutMenu_levelThreeLink:not(.responsiveFlyoutMenu_levelThreeLandingPageLink)',
        featuredList: '.responsiveFlyoutMenu_featuredList',
        focusableElements: 'a, input, select, button, [tabindex="0"]',
        navigationItemsToDisableWhenClosed: '[data-js-nav-index]:not(.responsiveFlyoutMenu_levelOneLink)',
        mobilePanelLevelTwo: '.responsiveFlyoutMenu_mobilePanel_levelTwo',
        mobilePanelLevelThree: '.responsiveFlyoutMenu_mobilePanel_levelThree',
        flyoutLevelTwoItem: '.responsiveFlyoutMenu_levelTwoItem',
        flyoutLevelThree: '.responsiveFlyoutMenu_levelThree',
        flyoutLevelThreeLink: '.responsiveFlyoutMenu_levelThreeLink:not(.responsiveFlyoutMenu_levelThreeLandingPageLink)',
        waterfallFlyOut: '.waterfallFlyOut',
        waterfallDetachedFeatureMenuLinks: '.responsiveFlyoutMenu_waterfallFlyOut_detachedFeatureMenu_section > a',
        levelTwoListDisplayName: '.responsiveFlyoutMenu_levelTwoList_displayName',
        waterfallLevelThreeLinks: '.responsiveFlyoutMenu_levelThreeLink, .responsiveFlyoutMenu_levelThreeList_displayName',
        notWaterfallLevelThreeLinks: 'a:not(.responsiveFlyoutMenu_levelThreeLink):not(.responsiveFlyoutMenu_levelThreeList_displayName)',
        elementsToHideFromScreenReader: 'div, ul',
        elementsToDisableOnClose: '.responsiveFlyoutMenu_levelTwo a',
      },
      classNames: {
        popUpLock: 'js-popup-lock',
        slideTransform: 'responsiveFlyoutMenu_transform',
        homeButtonShow: 'responsiveFlyoutMenu_menuHomeButton-show',
        storesButtonShow: 'responsiveFlyoutMenu_menuStoresButton-show',
        backButtonShow: 'responsiveFlyoutMenu_menuBackButton-show',
        stickyMenuShadow: 'responsiveFlyoutMenu_menuStickyRow-shadow',
        menuShowClass: 'responsiveFlyoutMenu_menu-show',
        menuUnderlayClass: 'responsiveFlyoutMenu_menuUnderlay-show',
        dynamicDropdownClass: 'responsiveFlyoutMenu_levelOneItem-dynamic',
        landingPageLink: 'responsiveFlyoutMenu_levelTwoLandingPageLink',
        dropdownShow: 'active',
        dropdownTransformClass: 'responsiveFlyoutMenu_levelTwoInner-transform',
        dropdownOpenedClass: 'responsiveFlyoutMenu_levelTwoInner-opened',
        hidePanelLeftClass: 'responsiveFlyoutMenu_mobilePanel_hideLeft',
        showPanelClass: 'responsiveFlyoutMenu_mobilePanel_show',
        active: 'active',
      },
      focusableElements: 'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]',
    };
    let dropdownAnimationTimer;

    const _init = (element) => {
      component.element = element;

      if ('ontouchstart' in document.documentElement) {
        component.config.attribs.dropdownOpenOnClick = true;
      }

      component.mobileNavMenuStatus = 'close';
      component.menu = component.element.querySelector(component.config.selectors.menu);
      component.menuStickyRow = component.element.querySelector(component.config.selectors.menuStickyRow);
      component.menuStickyRowControl = component.element.querySelectorAll(component.config.selectors.menuStickyRowControl);
      component.menuButton = document.querySelector(component.config.selectors.menuButton);
      component.closeMenu = component.element.querySelector(component.config.selectors.closeMenu);
      component.menuShow = component.menuButton.getAttribute(component.config.attribs.modClass);
      component.flyoutSlides = component.element.querySelectorAll(component.config.selectors.flyoutSlider);
      component.subNav = component.element.querySelectorAll(component.config.selectors.subNav);
      component.subNavLink = component.element.querySelectorAll(component.config.selectors.subNavLink);
      component.menuHome = component.element.querySelector(component.config.selectors.menuHome);
      component.menuStores = component.element.querySelector(component.config.selectors.menuStores);
      component.backToPrev = component.element.querySelector(component.config.selectors.backToPrev);
      component.hasSubnav = component.element.querySelectorAll(component.config.selectors.hasSubnav);
      component.menuUnderlay = document.querySelector(component.config.selectors.menuUnderlay);
      component.smallMenuUnderlay = component.element.querySelector(component.config.selectors.mobileUnderlay);
      component.levelOneContainer = component.element.querySelector(component.config.selectors.levelOneContainer);
      component.levelTwoContainers = component.element.querySelectorAll(component.config.selectors.levelTwoContainer);
      component.cardsLevelContainers = component.element.querySelectorAll(component.config.selectors.cardsLevelContainer);
      component.subMenu = component.element.querySelector(component.config.selectors.subMenu);
      component.subMenuElements = component.subMenu.querySelectorAll(component.config.selectors.focusableElements);
      component.levelOneLinks = component.element.querySelectorAll(component.config.selectors.levelOneLink);
      component.fastTrackSubmenuLink = component.element.querySelector(component.config.selectors.fastTrackSubmenuLink);
      component.responsiveSubMenuList = component.element.querySelector(component.config.selectors.responsiveSubMenuList);
      component.mobilePanelLevelTwo = component.element.querySelector(component.config.selectors.mobilePanelLevelTwo);
      component.mobilePanelLevelThree = component.element.querySelector(component.config.selectors.mobilePanelLevelThree);
      component.flyoutLevelTwoItem = component.element.querySelectorAll(component.config.selectors.flyoutLevelTwoItem);
      component.flyoutLevelThree = component.element.querySelectorAll(component.config.selectors.flyoutLevelThree);
      component.waterfallFlyOut = component.element.querySelector(component.config.selectors.waterfallFlyOut);
      component.headerBreakpoint = 1200;
      component.levelOne = '1';
      component.levelTwo = '2';
      component.levelThree = '3';
      component.currentLevel = 1;
      component.bind();
      component.equaliseCardHeights();
      component.levelOneElements = [...component.menuStickyRowControl, ...component.levelOneLinks, ...component.subMenuElements];

      if (component.getWindowWidth() >= component.headerBreakpoint) {
        component.disableElements(component.element.querySelectorAll(component.config.selectors.focusableElements));
        component.enableElements(component.element.querySelectorAll(`[${component.config.attribs.linkLevel}='${component.levelOne}']`));
      } else {
        component.disableElements(component.element.querySelectorAll(component.config.selectors.focusableElements));
        component.enableElement(component.menuButton);
      }

      component.levelsWithFeatures = [];
      return component;
    };

    const _bind = () => {
      component.bindMobile();

      const clickEvent = (e, containerElement) => {
        if (!e) return;
        const hasSubnav = containerElement.getAttribute('data-js-element') === 'hasSubnav';
        if (!hasSubnav) return;
        const doesNotHaveCoords = (e.clientX === 0 && e.clientY === 0) || (e.offsetX === 0 && e.offsetY === 0);
        const isNotMousePointerType = !!e.pointerType ? e.pointerType !== 'mouse' : doesNotHaveCoords;
        const isLevelOneTarget = !!e.target.attributes['data-js-nav-level'] && e.target.attributes['data-js-nav-level'].nodeValue === "1";
        const isValidClick = isNotMousePointerType && isLevelOneTarget;


        if (isValidClick && containerElement.getAttribute('data-focused') === 'true' && containerElement.getAttribute('aria-expanded') === 'false') {
          e.preventDefault();
          component.openDesktopSubMenu(containerElement);
          accessibilityAnnouncer.announce('polite', containerElement.querySelector('.responsiveFlyoutMenu_levelOneLink').textContent + ' expanded');
        }
      }

      var menuItems = component.levelOneContainer.children;
      Array.prototype.forEach.call(menuItems, function(containerElement, i){
        containerElement.setAttribute('aria-expanded', 'false');

        containerElement.addEventListener('focusin', () => {
          containerElement.setAttribute('data-focused', true);
          if (containerElement.getAttribute('aria-expanded') == 'false') {
            accessibilityAnnouncer.announce(
              'polite',
              containerElement.querySelector('.responsiveFlyoutMenu_levelOneLink').textContent + ' collapsed. Press Enter to expand.'
            );
          }

          containerElement.addEventListener('click', (e) => clickEvent(e, containerElement));
        });

        containerElement.addEventListener('focusout', () => {
          containerElement.removeAttribute('data-focused');
        })
      });

      /**
        Desktop Binding
       */
      // On mouse away from menu close the menu
      component.element.addEventListener('mouseleave', component.closeDesktopSubMenu);
      // On focus away from menu close the menu
      component.levelOneContainer.addEventListener('focusout', event => {
        if (event.relatedTarget &&
          event.relatedTarget instanceof Node &&
          !event.currentTarget.contains(event.relatedTarget)
        ) {
          component.closeDesktopSubMenu();
        }
      });

      if (component.waterfallFlyOut) {
        component.bindWaterfallFunctionality();
      }

      if (component.config.attribs.dropdownOpenOnClick) {
        component.bindOpenOnClick();
      } else {
        [...component.levelOneContainer.children].forEach((levelOneItem, index) => {
          // Override the default CSS behavior by changing the levelOneItem class
          if (levelOneItem.classList[0] !== component.config.classNames.dynamicDropdownClass
            && !levelOneItem.classList.contains(component.config.selectors.responsiveSubMenuList)
            && levelOneItem.tagName === 'LI') {

            const levelOneClass = levelOneItem.classList[0];
            levelOneItem.classList.remove(levelOneClass);
            levelOneItem.classList.add(levelOneClass + component.config.attribs.levelOneClassJsSuffix);

          }

          // Hide all container elements from screen reader when closed
          levelOneItem.querySelectorAll(component.config.selectors.elementsToHideFromScreenReader)
            .forEach(element => element.setAttribute('aria-hidden', 'true'))

          // Add open on Hover
          levelOneItem.addEventListener('mouseenter', () => component.openDesktopSubMenu(levelOneItem), false);

          // Add open on Focus
          const levelOneLink = levelOneItem.querySelector('a');
          if(levelOneLink) {
            // Add keyboard left and right navigation on level 1
            levelOneLink.addEventListener('keydown', (event) => {
              const moveTo = (newIndex) => {
                component.levelOneLinks[newIndex] && component.levelOneLinks[newIndex].focus();
              }
              switch (event.key) {
                case 'ArrowLeft':
                  event.preventDefault();
                  moveTo(index - 1);
                  break;
                case 'ArrowRight':
                  event.preventDefault();
                  moveTo(index + 1);
                  break;
                case 'Esc':
                case 'Escape':
                  event.preventDefault();
                  component.closeDesktopSubMenu(levelOneItem);
                  break;
              }
            });
          }

          // Add escape functionality to all child links that sets focus back to level 1 link
          const linkQuery = component.waterfallFlyOut ? component.config.selectors.notWaterfallLevelThreeLinks : 'a';
          levelOneItem.querySelectorAll(linkQuery).forEach((link) => {
            link.addEventListener('keydown', event => {
              if (component.getWindowWidth() >= component.headerBreakpoint) {
                if (event.key === 'Escape' || event.key === 'Esc') {
                  event.preventDefault();
                  if(levelOneLink) {
                    levelOneLink.focus();
                  }
                }
              }
            });
          });

          if (!component.waterfallFlyOut) {
            component.bindListFunctionality(levelOneItem);
          }
        });
      }

      if (component.fastTrackSubmenuLink) {
        component.fastTrackSubmenuLink.addEventListener('click', component.triggerGATracking);
      }

      if (component.hasSubnav[0] && component.hasSubnav[0].classList.contains(component.config.classNames.dynamicDropdownClass)) {
        component.sizeDropdownMenu();
        $window.addEventListener('optimizedResize', component.sizeDropdownMenu, false);
      } else {
        $window.addEventListener('optimizedResize', component.resizeEvent, false);
      }

      _throttle('resize', 'optimizedResize');
    };

    const _bindMobile = () => {
      component.menuButton.addEventListener('click', component.open);
      component.closeMenu.addEventListener('click', component.close);
      component.smallMenuUnderlay.addEventListener('click', component.close);
      component.backToPrev.addEventListener('click', component.back);

      [...component.flyoutSlides].forEach((slide, index) => {
        slide.setAttribute('data-subnav-index', index);
        slide.addEventListener('click', component.triggerSlide, false);
        if (index === (component.flyoutSlides.length - 1)) {
          component.allSubnavLinksFound = true;
        }
        slide.addEventListener('scroll', component.addShadow, false);
        slide.addEventListener('transitionend', component.removeShadow, false);
      });

      component.mobilePanelLevelTwo.addEventListener('scroll', component.addShadow, false);
      component.mobilePanelLevelTwo.addEventListener('transitionend', component.removeShadow, false);
      component.mobilePanelLevelThree.addEventListener('scroll', component.addShadow, false);
      component.mobilePanelLevelThree.addEventListener('transitionend', component.removeShadow, false);
    }

    const _bindListFunctionality = (levelOneItem) => {
      const levelTwoLinks = levelOneItem.querySelectorAll(component.config.selectors.flyoutMenuLevelTwoSelector);
      levelTwoLinks.forEach((levelTwoLink, index) => {
        const levelThreeLinks = levelTwoLink.nextElementSibling
          && levelTwoLink.nextElementSibling.querySelectorAll(component.config.selectors.flyoutLevelThreeLink);

        // Add left, right and down keyboard functionality to Level 2 Links
        levelTwoLink.addEventListener('keydown', event => {
          const moveTo = (newIndex) => {
            levelTwoLinks[newIndex] && levelTwoLinks[newIndex].focus();
          }
          switch (event.key) {
            case 'ArrowLeft':
              event.preventDefault();
              moveTo(index - 1);
              break;
            case 'ArrowRight':
              event.preventDefault();
              moveTo(index + 1);
              break;

            case 'ArrowDown':
              event.preventDefault();
              levelThreeLinks[0] && levelThreeLinks[0].focus();
              break;
          }
        });

        levelThreeLinks && levelThreeLinks.forEach((levelThreeLink, levelThreeIndex) => {
          levelThreeLink.addEventListener('keydown', event => {
            const moveHorizontal = (levelTwoIndex) => {
              const newLevelTwoList = levelTwoLinks[levelTwoIndex];
              if (newLevelTwoList) {
                const levelThreeList = newLevelTwoList.nextElementSibling.querySelectorAll(component.config.selectors.flyoutLevelThreeLink);
                levelThreeList && levelThreeList[levelThreeIndex] ? levelThreeList[levelThreeIndex].focus() : levelThreeList[levelThreeList.length - 1].focus();
              }
            };

            const moveVertical = (newIndex) => {
              if (newIndex === -1){
                levelTwoLink && levelTwoLink.focus();
              }
              levelThreeLinks[newIndex] && levelThreeLinks[newIndex].focus();
            }

            switch (event.key) {
              case 'ArrowLeft': {
                event.preventDefault();
                moveHorizontal(index - 1);
                break;
              }
              case 'ArrowRight': {
                event.preventDefault();
                moveHorizontal(index + 1);
                break;
              }
              case 'ArrowUp': {
                event.preventDefault();
                moveVertical(levelThreeIndex - 1);
                break;
              }
              case 'ArrowDown': {
                event.preventDefault();
                moveVertical(levelThreeIndex + 1);
                break;
              }
            }
          });
        });
      });
    };

    const _bindWaterfallFunctionality = () => {
      const closeWaterfall = () => {
        component.flyoutLevelThree.forEach(element => {
          element.classList.remove(component.config.classNames.active);
        });
        component.flyoutLevelTwoItem.forEach(levelTwoItem => {
          levelTwoItem.classList.remove(component.config.classNames.active);
        });
      };

      component.flyoutLevelTwoItem.forEach(item => {
        const openWaterfall = () => {
          component.flyoutLevelTwoItem.forEach(levelTwoItem => {
            levelTwoItem.classList.remove(component.config.classNames.active);
          });
          item.classList.add(component.config.classNames.active);
          component.flyoutLevelThree.forEach(element => {
            element.classList.remove(component.config.classNames.active);
          });
          const levelThreeChild = item.querySelector(component.config.selectors.flyoutLevelThree);
          levelThreeChild && levelThreeChild.classList.add(component.config.classNames.active);
        };

        // Add open listener to level 2
        item.addEventListener('mouseenter', openWaterfall);

        const link = item.querySelector('a');

        link.addEventListener('click', event => {
          openWaterfall();
        });

        link.addEventListener('keydown', event => {
          switch (event.key) {
            case ' ':
            case 'Enter':
            case 'ArrowRight':
              event.preventDefault();
              openWaterfall();
              break;
          }
        });

        // Add escape listener to level 3
        item.querySelectorAll(component.config.selectors.waterfallLevelThreeLinks)
          .forEach(levelThreeLink => levelThreeLink.addEventListener('keydown', event => {
              if (event.key === 'Escape' || event.key === 'Esc') {
                event.preventDefault();
                closeWaterfall();
                link.focus();
              }
          }));
      });

      component.levelTwoContainers.forEach(container => container.addEventListener('mouseleave', closeWaterfall));
    };

    const _bindOpenOnClick = () => {
      //Add listener for removing underlay when tapping outside the menu items
      $window.addEventListener('click', function (e) {
        component.hideMenuUnderlay();
        if (component.config.attribs.lastDropdownOpened) {
          component.config.attribs.lastDropdownOpened.removeAttribute(component.config.attribs.dropdownActive);
        }
      });
      //Stop click through when tapping outside the menu when it's open
      component.menuUnderlay.addEventListener('click', component.handleMenuUnderlayEvent);
      //Fix tablet issue on scroll
      component.menuUnderlay.addEventListener('touchstart', () => setTimeout(component.handleMenuUnderlayEvent, 50), {passive: true});

      Array.from(component.hasSubnav).map(el => el.addEventListener('click', component.handleTouch, false));
    };

    const _openDesktopSubMenu = (el) => {
      if(component.dropdownOpened === el){
        return;
      }
      if (dropdownAnimationTimer) {
        $window.clearTimeout(dropdownAnimationTimer);
      }

      if (el.hasAttribute(component.config.attribs.hasSubnav)) {

        dropdownAnimationTimer = $window.setTimeout(() => {
          component.dropdownToOpen = el;

          component.dropdownToOpen.classList.add(component.config.classNames.dropdownShow);

          if (component.dropdownOpened && component.dropdownToOpen !== component.dropdownOpened) {
            component.dropdownToOpen.querySelector(component.config.selectors.levelTwoInnerContainer)
              .classList.add(component.config.classNames.dropdownOpenedClass);
          } else {
            component.dropdownToOpen.querySelector(component.config.selectors.levelTwoInnerContainer)
              .classList.add(component.config.classNames.dropdownTransformClass);
          }


          if(siteObj.config.useGa4EnhancedEcom === true) {
            component.recordMenuLevelOneDesktop()
          }

          // Enable interactive elements for keyboard and screen reader when open
          component.enableElements(component.dropdownToOpen.querySelectorAll(component.config.selectors.elementsToDisableOnClose));
          // Show container elements from screen reader when open
          component.dropdownToOpen.querySelectorAll(component.config.selectors.elementsToHideFromScreenReader)
            .forEach(element => element.setAttribute('aria-hidden', 'false'));

          if (component.dropdownOpened) {
            component.dropdownOpened.setAttribute('aria-expanded', 'false');
          }

          component.dropdownToOpen.setAttribute('aria-expanded', 'true');

          component.closeDesktopSubMenu();
          component.showMenuUnderlay();
        }, 200);
      } else {
        dropdownAnimationTimer = $window.setTimeout(() => {
          component.closeDesktopSubMenu();
        }, 200);
      }
    };

    const _recordMenuLevelOneDesktop = () => {
      let menuLevelOneDesktop;
      localStorage && localStorage.clear('MenuLevel1');

      if (component && component.dropdownToOpen) {

        let aElement = component.dropdownToOpen.querySelector('a');
        if (aElement) {
          menuLevelOneDesktop = aElement.innerText;
        }
      }

      localStorage && localStorage.setItem('MenuLevel1', menuLevelOneDesktop);
    }

    const _closeDesktopSubMenu = (levelOneItem) => {
      if (dropdownAnimationTimer) {
        $window.clearTimeout(dropdownAnimationTimer);
      }

      if (component.dropdownOpened) {
        component.dropdownOpened.classList.remove(component.config.classNames.dropdownShow);
        let levelTwoInnerContainer = component.dropdownOpened.querySelector(component.config.selectors.levelTwoInnerContainer);
        if (levelTwoInnerContainer) {
          if (levelTwoInnerContainer && levelTwoInnerContainer.classList.contains(component.config.classNames.dropdownOpenedClass)) {
            levelTwoInnerContainer.classList.remove(component.config.classNames.dropdownOpenedClass);
          } else {
            levelTwoInnerContainer.classList.remove(component.config.classNames.dropdownTransformClass);
          }
        }
        component.disableElements(component.dropdownOpened.querySelectorAll(component.config.selectors.elementsToDisableOnClose));

        // Hide all container elements from screen reader when closed
        component.dropdownOpened.querySelectorAll(component.config.selectors.elementsToHideFromScreenReader)
          .forEach(element => element.setAttribute('aria-hidden', 'true'))

        component.dropdownOpened.setAttribute('aria-expanded', 'false');

        if (levelOneItem && levelOneItem.querySelector && levelOneItem.querySelector('.responsiveFlyoutMenu_levelOneLink')) {
          accessibilityAnnouncer.announce(
            'polite',
            levelOneItem.querySelector('.responsiveFlyoutMenu_levelOneLink').textContent + ' collapsed. Press Enter to expand.'
          );
        }
      }

      if (component.dropdownToOpen) {
        component.dropdownToOpen.setAttribute('aria-expanded', 'true');
      }

      component.dropdownOpened = component.dropdownToOpen ? component.dropdownToOpen : null;

      component.dropdownToOpen = null;
      component.hideMenuUnderlay();
    };

    const _triggerGATracking = () => {
      app.publish('tracking/record', 'Fast Track Engagement', 'Clicked Fast Pass | Header');
    };

    const _handleTouch = (e) => {
      if (component.mobileNavMenuStatus === 'close') {
        if (component.config.attribs.lastDropdownOpened && component.config.attribs.lastDropdownOpened !== e.target) {
          component.config.attribs.lastDropdownOpened.removeAttribute(component.config.attribs.dropdownActive);
          component.config.attribs.lastDropdownOpened.parentNode.classList.remove(component.config.classNames.dropdownShow);
        }
        if (e.target.hasAttribute(component.config.attribs.dropdownActive)) {
          e.target.removeAttribute(component.config.attribs.dropdownActive);
          e.target.parentNode.classList.remove(component.config.classNames.dropdownShow);
          component.hideMenuUnderlay();
        } else {
          component.showMenuUnderlay();
          e.target.setAttribute(component.config.attribs.dropdownActive, '');
          if (e.target.parentNode.classList.contains(component.config.classNames.dynamicDropdownClass)) {
            e.target.parentNode.classList.add(component.config.classNames.dropdownShow);
          }
          component.config.attribs.lastDropdownOpened = e.target;
        }
      }
    };

    const _addShadow = (e) => {
      const scrollPosition = e.target.scrollTop;
      if (scrollPosition > 5) {
        component.menuStickyRow.classList.add(component.config.classNames.stickyMenuShadow);
      } else {
        component.menuStickyRow.classList.remove(component.config.classNames.stickyMenuShadow);
      }
    };

    const _removeShadow = () => {
      component.menuStickyRow.classList.remove(component.config.classNames.stickyMenuShadow);
    };

    const _open = () => {
      component.prevScrollTop = $window.pageYOffset;
      component.menuButton.classList.add(component.menuShow);
      component.menuButton.setAttribute('aria-expanded', 'true');
      component.menuButton.parentElement.classList.add('responsiveFlyoutMenu-overlay');
      component.menu.classList.add(component.config.classNames.menuShowClass);
      component.smallMenuUnderlay.classList.add(component.config.classNames.menuUnderlayClass);
      component.mobileNavMenuStatus = 'open';
      document.documentElement.classList.add(component.config.classNames.popUpLock);
      document.body.style.top = `${-component.prevScrollTop}px`;

      component.enableElements(component.levelOneElements);
      component.disableElements([component.menuButton, component.backToPrev]);
      component.accessibleModalHelper = new accessibleModalHelper(component.menu, component.close, null, 0);

      app.publish('columbo/track', 'responsiveFlyoutMenu', 'open', 'mobile menu');
    };

    const _close = () => {
      component.menuButton.classList.remove(component.menuShow);
      component.menuButton.setAttribute('aria-expanded', 'false');
      component.menuButton.parentElement.classList.remove('responsiveFlyoutMenu-overlay');
      component.menu.classList.remove(component.config.classNames.menuShowClass);
      component.smallMenuUnderlay.classList.remove(component.config.classNames.menuUnderlayClass);
      component.mobileNavMenuStatus = 'close';
      document.documentElement.classList.remove(component.config.classNames.popUpLock);
      document.body.style.top = '';
      app.element.scrollTo(component.prevScrollTop);
      component.resetMenu();

      component.disableElements(component.element.querySelectorAll(component.config.focusableElements));
      component.enableElement(component.menuButton);
      component.accessibleModalHelper && component.accessibleModalHelper.close();

      app.publish('columbo/track', 'responsiveFlyoutMenu', 'close', 'mobile menu');
    };

    const _back = () => {
      if (component.mobileNavMenuStatus === 'open') {
        if (component.currentLevel === 2) {
          component.mobilePanelLevelTwo.classList.remove(component.config.classNames.showPanelClass);
          component.levelOneContainer.classList.remove(component.config.classNames.hidePanelLeftClass);
          component.backToPrev.classList.remove(component.config.classNames.backButtonShow);
          component.menuHome.classList.add(component.config.classNames.homeButtonShow);
          if(component.menuStores) {
            component.menuStores.classList.add(component.config.classNames.storesButtonShow);
          }

          component.currentLevel = 1;

          component.manageFocusOnSlide(component.mobilePanelLevelTwo, 1, component.levelOneContainer);

        } else if (component.currentLevel === 3) {
          component.mobilePanelLevelThree.classList.remove(component.config.classNames.showPanelClass);
          component.mobilePanelLevelTwo.classList.remove(component.config.classNames.hidePanelLeftClass);

          component.manageFocusOnSlide(component.mobilePanelLevelThree, 2, component.mobilePanelLevelTwo);

          component.currentLevel = 2;
        }
      }
    };

    const _resetMenu = () => {
      Array.from(component.flyoutSlides).map(el => {
        if (el.classList.contains(component.config.classNames.slideTransform)) {
          el.classList.remove(component.config.classNames.slideTransform);
        }
      });
      Array.from(component.subNav).map(el => el.scrollTop = 0);
      component.backToPrev.classList.remove(component.config.classNames.backButtonShow);
      component.menuHome.classList.add(component.config.classNames.homeButtonShow);
      if(component.menuStores) {
        component.menuStores.classList.add(component.config.classNames.storesButtonShow);
      }

      component.currentLevel = 1;
      component.levelOneContainer.classList.remove(component.config.classNames.hidePanelLeftClass);
      component.mobilePanelLevelTwo.classList.remove(component.config.classNames.showPanelClass, component.config.classNames.hidePanelLeftClass);
      component.mobilePanelLevelThree.classList.remove(component.config.classNames.showPanelClass, component.config.classNames.hidePanelLeftClass);
    };

    const _showMenuUnderlay = () => {
      component.menuUnderlay.classList.add(component.config.classNames.menuUnderlayClass);
    };

    const _hideMenuUnderlay = () => {
      component.menuUnderlay.classList.remove(component.config.classNames.menuUnderlayClass);
    };

    const _recordMobileNavigationLevel = (level, menuName) => {

      const menuLevel = `menuLevel${level}`;

      if (localStorage) {
        localStorage.removeItem(menuLevel);
        localStorage.removeItem('menuLevel3');
        parseInt(level) === 1 && localStorage.removeItem('menuLevel2');
        localStorage.setItem(menuLevel, menuName);
      }
    }


    const _triggerSlide = (e) => {

      const target = e.target.closest('a');
      if (target && target.dataset) {
        const targetDataset = target.dataset;
        const menuLevel = targetDataset.jsNavLevel;
        const menuName = targetDataset.context;

        component.recordMobileNavigationLevel(menuLevel, menuName)
      }

      const linkHasChildren = e.target.className.includes('hasChildren');

      if (!linkHasChildren || component.mobileNavMenuStatus === 'close') {
        return;
      }

      e.preventDefault();

      if (component.mobileNavMenuStatus === 'open') {
        // Create panel
        const sibling = e.target.nextElementSibling;
        if (sibling) {
          const linkLevel = e.target.getAttribute(component.config.attribs.linkLevel);
          const nextLevel = Number(linkLevel) + 1;

          // Level One to Two
          if (nextLevel === 2) {
            component.backToPrev.classList.add(component.config.classNames.backButtonShow);
            component.menuHome.classList.remove(component.config.classNames.homeButtonShow);
            if(component.menuStores) {
              component.menuStores.classList.remove(component.config.classNames.storesButtonShow);
            }
            component.levelOneContainer.classList.add(component.config.classNames.hidePanelLeftClass);
            const cards = sibling.classList.contains('responsiveFlyoutMenu_levelTwo-cards');
            const contents = sibling.querySelector('.responsiveFlyoutMenu_levelTwoList');
            component.slideToPanel(2, contents, cards);
            component.currentLevel = 2;
          }
          // Level Two to Three
          else if (nextLevel === 3) {
            component.slideToPanel(3, sibling);
            component.currentLevel = 3;
          }

        }
      }
    };

    const _slideToPanel = (panelIndex, contents, cards = false) => {
      // Get Panel Element from Index
      let panel;
      if (panelIndex === 2)
        panel = component.mobilePanelLevelTwo;
      else if (panelIndex === 3)
        panel = component.mobilePanelLevelThree;
      else return;

      // Set up panel contents and add event listeners
      panel.innerHTML = '';
      if(siteObj.config.showMobileIds) {
        let contentsClone = contents.cloneNode(true);
        if (panelIndex === 2) {
          const levelTwoLink = contentsClone.querySelectorAll('.responsiveFlyoutMenu_levelTwoLink')
          levelTwoLink && levelTwoLink.forEach(link => {
            const overrideValue = link.getAttribute('data-override-value');
            link.setAttribute('data-cs-override-id', overrideValue);
          })
          const levelTwoHeading = contentsClone.querySelectorAll('.responsiveFlyoutMenu_levelTwoLandingPageLink')
          levelTwoHeading && levelTwoHeading.forEach(heading => {
            const overrideValue = heading.getAttribute('data-override-value');
            heading.setAttribute('data-cs-override-id', overrideValue);
          })
        }
        if (panelIndex === 3) {
          const levelThreeLink = contentsClone.querySelectorAll('.responsiveFlyoutMenu_levelThreeLink')
          levelThreeLink && levelThreeLink.forEach(link => {
            const overrideValue = link.getAttribute('data-override-value');
            link.setAttribute('data-cs-override-id', overrideValue);
          })
          const levelThreeHeading = contentsClone.querySelectorAll('.responsiveFlyoutMenu_levelThreeLandingPageLink')
          levelThreeHeading && levelThreeHeading.forEach(heading => {
            const overrideValue = heading.getAttribute('data-override-value');
            heading.setAttribute('data-cs-override-id', overrideValue);
          })
        }
        panel.appendChild(contentsClone);
      } else {
        panel.appendChild(contents.cloneNode(true));
      }
      panel.querySelectorAll('a, .responsiveFlyoutMenu_levelTwoLink').forEach(link => link.addEventListener('click', component.triggerSlide, false));

      // Complete panel specific actions
      if (panelIndex === 2) {
        // Add Card Styling
        if (cards) {
          panel.classList.add('responsiveFlyoutMenu_mobilePanel_hasCards');
        } else {
          panel.classList.remove('responsiveFlyoutMenu_mobilePanel_hasCards');
        }

      } else if (panelIndex === 3) {
        // Shift level 2 left
        component.mobilePanelLevelTwo.classList.add(component.config.classNames.hidePanelLeftClass);
      }

      // Show Panel
      panel.classList.add(component.config.classNames.showPanelClass);

      component.manageFocusOnSlide(panel, panelIndex, panel);
    };

    /**
     * _manageFocusOnSlide - Handle focus order for keyboard and screen reader users
     *
     * 1. Disable all menu elements
     * 2. Enable current frame of navigation links
     * 3. Manage elements in menuStickyRow (Home, Back and Close)
     * 4. Wait until transitionend
     * 5. Reload accessibleModalHelper with new focusable elements
     * 6. Remove listener
     *
     * @param slidingElement - element to which attach transition end listener
     * @param newLevel - index of next level e.g. 2
     * @param newLevelLinksParent - parent of links to enable
     * @private
     */
    const _manageFocusOnSlide = (slidingElement, newLevel, newLevelLinksParent) => {
      component.disableElements(component.element.querySelectorAll(component.config.focusableElements));
      const navLinks = newLevelLinksParent.querySelectorAll(`[${component.config.attribs.linkLevel}='${newLevel}']`);
      if (newLevel === 1) {
        component.enableElements([...component.menuStickyRowControl, ...navLinks, ...component.subMenuElements]);
      } else {
        component.enableElements([...component.menuStickyRowControl, ...navLinks]);
      }

      slidingElement.addEventListener('transitionend', function transitionEndListener() {
        component.accessibleModalHelper && navLinks && component.accessibleModalHelper.reload(component.element, navLinks[0]);
        slidingElement.removeEventListener('transitionend', transitionEndListener);
      });
    };

    const _resizeEvent = () => {
      if (component.getWindowWidth() > component.headerBreakpoint) {
        if (component.mobileNavMenuStatus !== 'close') {
          component.close();
        }
        component.disableElements(component.element.querySelectorAll(component.config.focusableElements));
        component.enableElements(component.element.querySelectorAll(`[${component.config.attribs.linkLevel}='${component.levelOne}']`));
        component.element.querySelectorAll(component.config.selectors.listsToHideWhenClosed).forEach(list => list.setAttribute('aria-hidden', 'true'));
      } else {
        component.menuUnderlay.classList.remove(component.config.classNames.menuUnderlayClass);
        component.element.querySelectorAll(component.config.selectors.listsToHideWhenClosed).forEach(list => list.setAttribute('aria-hidden', 'false'));
        if (component.mobileNavMenuStatus === 'close') {
          component.disableElements(component.element.querySelectorAll(component.config.focusableElements));
          component.enableElement(component.menuButton);
        }
      }
    };

    const _getWindowWidth = () => {
      return $window.innerWidth;
    };

    var _throttle = function (type, name, comp) {
      comp = comp || $window;
      var running = false;
      var func = function () {
        if (running) {
          return;
        }
        running = true;
        requestAnimationFrame(function () {
          comp.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      comp.addEventListener(type, func);
    };

    const _sizeDropdownMenu = () => {
      var targetWidth = component.element.getBoundingClientRect().right - component.element.getBoundingClientRect().left;
      var headerMenuOffset = component.element.getBoundingClientRect().left;

      component.levelTwoContainers.forEach(level => {
        //first reset the left/right properties to the default values if resizing from mobile to desktop
        if (level.style.left === '100%') {
          level.style.left = 0;
          level.style.right = 'unset';
        }

        var levelWidth = level.getBoundingClientRect().right - level.getBoundingClientRect().left;
        var levelX = level.parentNode.getBoundingClientRect().left;
        var parentWidth = level.parentNode.getBoundingClientRect().right - level.parentNode.getBoundingClientRect().left;

        if (component.getWindowWidth() >= component.headerBreakpoint) {
          if (Math.abs(targetWidth - levelWidth) > 5) {
            level.style.left = 'auto';
            var screenOffset = levelX + levelWidth - targetWidth;
            var elementOffset = levelWidth / 2 - parentWidth / 2;
            var styleRightValue = elementOffset - screenOffset + headerMenuOffset;

            //Negative value means the menu overflows to the right to make it right align
            styleRightValue = styleRightValue < 0 ? 0 : styleRightValue;

            //Too large a value means the menu overflows to the left so make it left align
            if (levelWidth + styleRightValue > targetWidth) {
              level.style.left = 0;
              level.style.right = 'auto';
            } else {
              level.style.right = styleRightValue + 'px';
            }
          }
        } else {
          level.style.left = '100%';
          level.style.right = 0;
        }
      });

      component.resizeEvent();
    };

    const _handleMenuUnderlayEvent = (e) => {
      if (component.menuUnderlay.classList.contains(component.config.classNames.menuUnderlayClass)) {
        if (e && e.type === 'click') {
          e.preventDefault();
        }
        if (component.config.attribs.lastDropdownOpened) {
          component.config.attribs.lastDropdownOpened.removeAttribute(component.config.attribs.dropdownActive);
          component.hideMenuUnderlay();
        }
      }
    };

    const _equaliseCardHeights = () => {
      if (component.cardsLevelContainers.length > 0) {
        Array.from(component.cardsLevelContainers).map(el => {
          const cardsList = el.firstElementChild;

          let minHeight = -1;

          Array.from(cardsList.children).map(cardItem => {
            if (!cardItem.firstElementChild.classList.contains(component.config.classNames.landingPageLink)) {
              const currentHeight = $window.getComputedStyle(cardItem.firstElementChild).getPropertyValue('height');
              const currentHeightValue = parseFloat(currentHeight.substring(0, currentHeight.indexOf('px')));

              if (currentHeightValue > minHeight) {
                minHeight = currentHeightValue;
              }
            }
          });

          if (minHeight > 0) {
            Array.from(cardsList.children).map(cardItem => {
              if (!cardItem.firstElementChild.classList.contains(component.config.classNames.landingPageLink)) {
                cardItem.firstElementChild.style.height = minHeight + 'px';
              }
            });
          }
        });
      }
    };

    const _enableElement = (element) => {
      if (!element) return;
      element.setAttribute('tabindex', '0');
      element.setAttribute('aria-hidden', 'false');
    };

    const _disableElement = (element) => {
      if (!element) return;
      element.setAttribute('tabindex', '-1');
      element.setAttribute('aria-hidden', 'true');
    };

    const _enableElements = (elements) => {
      elements && Array.prototype.slice.call(elements).forEach(element => component.enableElement(element));
    };

    const _disableElements = (elements) => {
      elements && Array.prototype.slice.call(elements).forEach(element => component.disableElement(element));
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.bindMobile = _bindMobile;
    component.bindListFunctionality = _bindListFunctionality;
    component.bindWaterfallFunctionality = _bindWaterfallFunctionality;
    component.bindOpenOnClick = _bindOpenOnClick;
    component.openDesktopSubMenu = _openDesktopSubMenu;
    component.closeDesktopSubMenu = _closeDesktopSubMenu;
    component.triggerSlide = _triggerSlide;
    component.open = _open;
    component.close = _close;
    component.back = _back;
    component.resetMenu = _resetMenu;
    component.showMenuUnderlay = _showMenuUnderlay;
    component.hideMenuUnderlay = _hideMenuUnderlay;
    component.resizeEvent = _resizeEvent;
    component.addShadow = _addShadow;
    component.removeShadow = _removeShadow;
    component.getWindowWidth = _getWindowWidth;
    component.sizeDropdownMenu = _sizeDropdownMenu;
    component.handleTouch = _handleTouch;
    component.handleMenuUnderlayEvent = _handleMenuUnderlayEvent;
    component.equaliseCardHeights = _equaliseCardHeights;
    component.triggerGATracking = _triggerGATracking;
    component.enableElement = _enableElement;
    component.disableElement = _disableElement;
    component.enableElements = _enableElements;
    component.disableElements = _disableElements;
    component.slideToPanel = _slideToPanel;
    component.manageFocusOnSlide = _manageFocusOnSlide;
    component.recordMenuLevelOneDesktop = _recordMenuLevelOneDesktop;
    component.recordMobileNavigationLevel = _recordMobileNavigationLevel;
    return component;
  };

  return responsiveFlyoutMenu;
});
